
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import CreateMixin from "@/mixins/Crud/CreateMixin";
import AppConfig from "@/config/AppConfig";
import DomainForm from "@/views/SslCerts/domain/DomainForm.vue";

const DomainModule = namespace(AppConfig.DOMAIN_STORE);

@Component({
  components: { DomainForm },
  mixins: [CreateMixin],
})
export default class DomainCreate extends Vue {
  protected name = "DomainCreate";

  protected get resource(): string {
    return "/domain";
  }

  protected get descriptionField(): string {
    return "domain_name";
  }

  @DomainModule.Action("create")
  protected createItemAction: any;

  protected redirectAfterCreateSuccess(responseData: any): void {
    this.$router.replace({
      name: "DomainUpdate",
      params: { id: responseData.id },
    });
  }
}
